// tabbar
export const tabbar: string[] = [
	'/pages/home',
	// '/pages/goods/list',
	// '/pages/invest/index',
	// '/pages/order/cart',
	// '/pages/wallet/index',
	'/pages/mycenter/index',
	// '/pages/community/index',
	// '/pages/playlet/follow',
	'/uni_modules/uni-im/pages/contacts/contacts',
];

// 原生
export const native: string[] = [
	...tabbar,
	'/pages/community/expert',
	'/pages/community/kitchen',
	'/pages/community/question',
	'/pages/community/school',
	'/pages/goods/list',
	'/pages/goods/detail',
	'/pages/goods/anti-fake',
	'/pages/goods/search/list',
	'/pages/goods/search/index',
	'/pages/mycenter/login/index',
	'/pages/mycenter/login/pwd',
	'/pages/mycenter/login/register',
	'/pages/mycenter/collect',
	'/pages/mycenter/agreement',
	'/pages/order/list',
	'/pages/order/detail',
	'/pages/order/submit',
	'/pages/order/subcart',
	'/pages/order/express/list',
	'/pages/order/express/detail',
	'/pages/setting/index',
	'/pages/setting/pwd',
	'/pages/setting/mobile',
	'/pages/setting/agreement',
	'/pages/setting/location/edit',
	'/pages/setting/location/index',
	'/pages/agent/index',
	'/pages/agent/client',
	'/pages/agent/invite',
	'/pages/agent/earnest',
	'/pages/agent/scan/index',
	'/pages/agent/scan/freight',
	'/pages/agent/scan/tracing',
	'/pages/agent/scan/record',
	'/pages/agent/scan/detail',
	'/pages/agent/inventory/list',
	'/pages/agent/inventory/detail',
	'/pages/agent/inventory/deliver',
	'/pages/agent/order/cart',
	'/pages/agent/order/list',
	'/pages/agent/order/detail',
	'/pages/wallet/index',
	'/pages/wallet/income',
	'/pages/wallet/record',
	'/pages/wallet/transfer',
	'/pages/wallet/withdraw/index',
	'/pages/wallet/withdraw/record',
	'/pages/wallet/recharge/index',
	'/pages/wallet/recharge/record',
	'/pages/wallet/exchange',
	'/pages/wallet/setting/index',
	'/pages/wallet/setting/wechat',
	'/pages/wallet/setting/bankcard',
	'/pages/wallet/setting/alipay',
	'/pages/wallet/earnings',
	'/pages/invest/index',
	'/pages/invest/record',
	'/pages/notice/index',
	'/pages/notice/detail',
	'/pages/playlet/detail',
	'/pages/setting/download',
	'/uni_modules/uni-im/pages/index/index',
	'/uni_modules/uni-im/pages/common/view-code-page/view-code-page',
	'/uni_modules/uni-im/pages/userList/userList',
	'/uni_modules/uni-im/pages/chat/chat',
	'/uni_modules/uni-im/pages/common/video/video',
	'/uni_modules/uni-im/pages/group/info',
	'/uni_modules/uni-im/pages/contacts/notification/notification',
	'/uni_modules/uni-im/pages/contacts/contacts',
	'/uni_modules/uni-im/pages/contacts/addPeopleGroups/addPeopleGroups',
	'/uni_modules/uni-im/pages/contacts/createGroup/createGroup',
	'/uni_modules/uni-im/pages/group/qrCode',
	'/uni_modules/uni-im/pages/contacts/groupList/groupList',
	'/uni_modules/uni-im/pages/chat/info',
	'/uni_modules/uni-im/pages/group/members',
	'/uni_modules/uni-id-pages/pages/userinfo/userinfo',
	'/uni_modules/uni-id-pages/pages/login/login-withoutpwd',
	'/uni_modules/uni-id-pages/pages/login/login-withpwd',
	'/uni_modules/uni-id-pages/pages/userinfo/deactivate/deactivate',
	'/uni_modules/uni-id-pages/pages/userinfo/bind-mobile/bind-mobile',
	'/uni_modules/uni-id-pages/pages/login/login-smscode',
	'/uni_modules/uni-id-pages/pages/register/register',
	'/uni_modules/uni-id-pages/pages/retrieve/retrieve',
	'/uni_modules/uni-id-pages/pages/common/webview/webview',
	'/uni_modules/uni-id-pages/pages/userinfo/change_pwd/change_pwd',
	'/uni_modules/uni-id-pages/pages/register/register-by-email',
	'/uni_modules/uni-id-pages/pages/retrieve/retrieve-by-email',
	'/uni_modules/uni-id-pages/pages/userinfo/set-pwd/set-pwd',
	'/uni_modules/uni-id-pages/pages/userinfo/cropImage/cropImage',
	'/uni_modules/uni-id-pages/pages/register/register-admin',
];

// 不需要登录
export const noLogin: string[] = [
	'/pages/mycenter/login/index',
	'/pages/mycenter/login/pwd',
	'/pages/mycenter/login/register',
	'/pages/setting/agreement',
	'/pages/setting/download',
];

// 需要登录
export const needLogin: string[] = [
	'/pages/mycenter/collect',
	'/pages/order/list',
	'/pages/order/cart',
	'/pages/order/detail',
	'/pages/order/submit',
	'/pages/order/subcart',
	'/pages/order/express/list',
	'/pages/order/express/detail',
	'/pages/setting/index',
	'/pages/setting/mobile',
	'/pages/setting/location/edit',
	'/pages/setting/location/index',
	'/pages/agent/index',
	'/pages/agent/client',
	'/pages/agent/invite',
	'/pages/agent/earnest',
	'/pages/agent/scan/index',
	'/pages/agent/scan/freight',
	'/pages/agent/scan/tracing',
	'/pages/agent/scan/record',
	'/pages/agent/inventory/list',
	'/pages/agent/inventory/detail',
	'/pages/agent/inventory/deliver',
	'/pages/agent/order/cart',
	'/pages/agent/order/list',
	'/pages/agent/order/detail',
	'/pages/wallet/index',
	'/pages/wallet/income',
	'/pages/wallet/record',
	'/pages/wallet/transfer',
	'/pages/wallet/transfer/index',
	'/pages/wallet/transfer/record',
	'/pages/wallet/withdraw/index',
	'/pages/wallet/withdraw/record',
	'/pages/wallet/recharge/index',
	'/pages/wallet/recharge/record',
	'/pages/wallet/exchange',
	'/pages/wallet/setting/index',
	'/pages/wallet/setting/wechat',
	'/pages/wallet/setting/bankcard',
	'/pages/wallet/setting/alipay',
	'/pages/invest/record',
	'/pages/wallet/earnings',
	'/pages/playlet/follow',
	'/pages/playlet/detail',
	'/uni_modules/uni-im/pages/index/index',
	'/uni_modules/uni-im/pages/common/view-code-page/view-code-page',
	'/uni_modules/uni-im/pages/userList/userList',
	'/uni_modules/uni-im/pages/chat/chat',
	'/uni_modules/uni-im/pages/common/video/video',
	'/uni_modules/uni-im/pages/group/info',
	'/uni_modules/uni-im/pages/contacts/notification/notification',
	'/uni_modules/uni-im/pages/contacts/contacts',
	'/uni_modules/uni-im/pages/contacts/addPeopleGroups/addPeopleGroups',
	'/uni_modules/uni-im/pages/contacts/createGroup/createGroup',
	'/uni_modules/uni-im/pages/group/qrCode',
	'/uni_modules/uni-im/pages/contacts/groupList/groupList',
	'/uni_modules/uni-im/pages/chat/info',
	'/uni_modules/uni-im/pages/group/members',
	'/uni_modules/uni-id-pages/pages/userinfo/userinfo',
	'/uni_modules/uni-id-pages/pages/login/login-withoutpwd',
	'/uni_modules/uni-id-pages/pages/login/login-withpwd',
	'/uni_modules/uni-id-pages/pages/userinfo/deactivate/deactivate',
	'/uni_modules/uni-id-pages/pages/userinfo/bind-mobile/bind-mobile',
	'/uni_modules/uni-id-pages/pages/login/login-smscode',
	'/uni_modules/uni-id-pages/pages/register/register',
	'/uni_modules/uni-id-pages/pages/retrieve/retrieve',
	'/uni_modules/uni-id-pages/pages/common/webview/webview',
	'/uni_modules/uni-id-pages/pages/userinfo/change_pwd/change_pwd',
	'/uni_modules/uni-id-pages/pages/register/register-by-email',
	'/uni_modules/uni-id-pages/pages/retrieve/retrieve-by-email',
	'/uni_modules/uni-id-pages/pages/userinfo/set-pwd/set-pwd',
	'/uni_modules/uni-id-pages/pages/userinfo/cropImage/cropImage',
	'/uni_modules/uni-id-pages/pages/register/register-admin',
];
